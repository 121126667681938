/* Container styling */
.container {
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

/* Label styling */
.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* Input field styling */
.input-field,
.textarea-field,
.select-field {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

/* Textarea field styling */
.textarea-field {
  height: 200px;
  resize: vertical;
}

/* Button styling */
.download-button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
  transition: background 0.3s ease;
}

.download-button:hover {
  background: #0056b3;
}

/* Select field styling */
.select-field {
  width: 100px;
}
